import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useMsal } from '@azure/msal-react';
import Markdown from 'react-markdown';
import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from '../loader/Loader';
import Button from '../buttons/button/Button';
import CloseableModal from '../modal/ClosableModal';
import {
  getInsightRemediationCompleted,
  getInsightRemediationError,
  getInsightRemediationFailures,
  getInsightRemediationLoading,
} from '../../redux/selector/insightsSelector';
import { getLoginRequest } from '../../auth/azure-auth-config';
import { clearRemediation, remediateInsights } from '../../redux/slicers/insightSlicer';
import { INTERNAL_SERVER_ERROR } from '../../consts/erros';

export function LinkRenderer({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}

export default function AutoRemediateModal({
  platform,
  modelOpen,
  setModalOpen,
  remediationActions = '',
  scopeSubType,
  isDemo,
  insights,
}) {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { user } = useAuth0();
  const remediationSuccesses = useSelector(getInsightRemediationCompleted);
  const isLoading = useSelector(getInsightRemediationLoading);
  const remediationFailures = useSelector(getInsightRemediationFailures);
  const error = useSelector(getInsightRemediationError);
  const [authError, setAuthError] = useState(null);
  const remediationSuccess = !isEmpty(remediationSuccesses);
  const { t } = useTranslation(['remediation', 'common']);

  const errorMessage = authError || remediationFailures[0]?.error;

  const remediationCompleted = remediationSuccesses.length + remediationFailures.length;
  const progress = (remediationCompleted / insights.length) * 100;

  useEffect(
    () => () => {
      dispatch(clearRemediation());
    },
    [modelOpen],
  );

  async function handleRemediate() {
    try {
      // todo: remove when applied to all insights with remediationActions
      if (isDemo) {
        setModalOpen(false);
      } else {
        const result = await instance.loginPopup(getLoginRequest(scopeSubType));
        dispatch(
          remediateInsights({
            accessToken: result.accessToken,
            insightIds: insights.map((i) => i.id),
            auditUserName: user.name,
            auditUserEmail: user.email,
          }),
        );
      }
    } catch (e) {
      setAuthError(e.message);
    }
  }

  function renderFailure() {
    return (
      <div className="error">
        {!!remediationFailures.length && (
          <div>{t('remediationResult.remediationFailure', { failed: remediationFailures.length })}</div>
        )}
        <div>{errorMessage}</div>
        {errorMessage === INTERNAL_SERVER_ERROR && (
          <div className="text-button">
            <button type="button" className="link" onClick={handleRemediate}>
              {t('general.pleaseTryAgain', { ns: 'common' })}
            </button>
          </div>
        )}
      </div>
    );
  }

  function renderRemediation() {
    return (
      <>
        <div className="text-container">
          {t('remediationModal.remediationConfirmMessage', { platform })}
          <div className="text">
            {(remediationActions || '').split('\n').map((line) => (
              <Markdown key={line} components={{ a: LinkRenderer }}>
                {line}
              </Markdown>
            ))}
          </div>
        </div>
        <div className="buttons-row">
          <Button text={t('general.cancel', { ns: 'common' })} onClick={() => setModalOpen(false)} isFilled={false} />
          <Button text={t('general.approve', { ns: 'common' })} onClick={handleRemediate} isFilled />
        </div>
      </>
    );
  }

  return (
    <CloseableModal open={modelOpen} closeModal={() => setModalOpen(false)}>
      <div className="title">{t('remediationModal.title')}</div>
      {isLoading && (
        <>
          <Loader />
          {insights.length > 1 && (
            <>
              <LinearProgress variant="determinate" value={progress} />
              <div className="center">
                {t('remediationResult.loaderMessage', {
                  completed: remediationCompleted,
                  count: insights.length,
                })}
              </div>
            </>
          )}
        </>
      )}
      {errorMessage && (
        <div className="error centered">
          <div className="remediation-emoji">🩹</div>
          {renderFailure()}
        </div>
      )}
      {!!(progress === 100 && remediationSuccess) && (
        <div className="success centered">
          <div className="remediation-emoji">🏆</div>
          <div>
            {t('remediationResult.remediationSuccess', {
              completed: remediationSuccesses.length,
              count: insights.length,
            })}
          </div>
          {remediationFailures.length > 0 && renderFailure()}
        </div>
      )}
      {!isLoading && !errorMessage && !remediationSuccess && renderRemediation()}
      {(error || remediationSuccess) && (
        <div className="buttons-row">
          <Button text={t('general.done', { ns: 'common' })} onClick={() => setModalOpen(false)} isFilled />
        </div>
      )}
    </CloseableModal>
  );
}
